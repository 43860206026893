import { Injectable } from '@angular/core';
import { AppState } from '../reducers/app.state';
import { Profile } from '../reducers/model/profile.model';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    profile: Profile;
    asyncProfile = new BehaviorSubject(null);
    isAcademyOrg: boolean; /// Check if current user is under Academy Organization.

    constructor(private store: Store<AppState>) {
        this.store.select('profile').subscribe((res: any) => {
            this.profile = res[0];
            this.asyncProfile.next(res[0]);
            this.isAcademyOrg = this.profile?.organization?.setting?.TEAM_SHIFT_REQUIRED;
        });
    }
}
