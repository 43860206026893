import { Component, OnInit, HostListener } from '@angular/core';
import { SetupService } from '../shared/service/setup.service';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { CurrentUserService } from 'src/app/shared/services/current-user.service';
import { async } from '@angular/core/testing';
declare var $: any;

@Component({
    selector: 'app-agree-terms',
    templateUrl: './agree-terms.component.html',
    styleUrls: ['./agree-terms.component.scss']
})
export class AgreeTermsComponent implements OnInit {
    isSubmitted = false;
    disabled = true;
    constructor(
        private _setupService: SetupService,
        private _alertService: AlertMessageService,
        private _loginService: LoginService,
        private router: Router
    ) {}

    ngOnInit() {
        $('#MdTerm').modal('show');
    }

    onAgree() {
        this.isSubmitted = true;
        this.disabled = true;
        this._setupService.agreeTerms().subscribe(
            () => {
                setTimeout(async () => {
                    const profile: any = await this._loginService.getCurrentProfile();
                    console.log('pro', profile);
                    if (profile.isTermsAgreed) {
                        $('#MdTerm').modal('hide');
                        this.isSubmitted = false;
                        this.disabled = false;
                        this.router.navigate(['/']);
                    } else {
                        this.isSubmitted = false;
                        this.disabled = false;
                        this._alertService.alertError('Cannot agree the terms');
                    }
                }, 500);
            },
            err => {
                this.isSubmitted = false;
                this.disabled = false;
                this._alertService.alertError(err.error.message);
            }
        );
    }

    // event on scroll
    @HostListener('scroll', ['$event'])
    onScroll(event) {
        const target = event.target;
        if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
            console.log('-----');
            this.disabled = false;
        }
    }
}
