import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventMemberType'
})
export class EventMemberTypePipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'TEAM': return 'Team';
            case 'EXTERNAL': return 'External User';
            case 'USER': return 'Internal User';
        }
    }
}
