import { Component, OnInit } from '@angular/core';
import { Auth0Service } from '../../../shared/services/auth0.service';
import { LoginService } from '../../../shared/services/login.service';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as ProfileAction from '../../../shared/reducers/profile.actions';
import { AppState } from 'src/app/shared/reducers/app.state';
import { OrganizationService } from '../../hr/shared/service/organization.service';
import { OrganizationDetail } from '../../hr/shared/model/organization';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {
    error: string;
    errorDescription: string;
    constructor(
        private route: Router,
        private _auth0Service: Auth0Service,
        private _loginService: LoginService,
        private _organizationService: OrganizationService,
        private store: Store<AppState>
    ) {}

    async ngOnInit() {
        try {
            await this._auth0Service.handleAuthentication();
        } catch (error) {
            this.route.navigate(['/login'], {
                queryParams: { message: error ? error.errorDescription : '' }
            });
        }

        const profile: any = await this._loginService.getCurrentProfile();

        if (profile) {
            if (profile?.organization?.isSetup === 0) {
                this.route.navigate(['/onboarding']);
            } else if (profile?.isLead) {
                this.route.navigate(['/leads']);
            } else if (profile?.isSystemAdmin) {
                this.route.navigate(['/organization']);
            } else {
                this.route.navigate(['/organization-profile']);
            }
        }

        // this._loginService.getProfile().subscribe(
        //     (res: any) => {
        //         res.hasProfile = res.organization.latitude ? true : false;
        //         res.isSystemAdmin = res.permissions.includes(
        //             'CREATE:ORGANIZATION'
        //         );
        //         // set data to ngrx store
        //         this.store.dispatch(new ProfileAction.AddProfile(res));
        //         if (!res.isSystemAdmin) {
        //             this.route.navigate(['/organization-profile']);
        //         } else {
        //             this.route.navigate(['/organization']);
        //         }
        //     },
        //     err => {
        //         // error remove localStorage and redirect to login again
        //         localStorage.removeItem('token');
        //         localStorage.removeItem('organizations');
        //         this.route.navigate(['/login'], {
        //             queryParams: {
        //                 message: err && err.error ? err.error.message : ''
        //             }
        //         });
        //     }
        // );
    }
}
