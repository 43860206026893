import { Injectable, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from 'src/app/shared/services/current-user.service';
import { Profile } from '../model';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService implements OnDestroy {
    apiV1 = this._globalService.apiUrl;
    apiV2 = this._globalService.apiUrlV2;
    organizationId: number;
    userProfileSubscription: Subscription;


    constructor(
        private _globalService: GlobalService,
        private _http: HttpClient,
        private _currentUser: CurrentUserService
    ) {
        this.userProfileSubscription = this._currentUser.asyncProfile.subscribe((pf: Profile) => this.organizationId = pf?.organizationId);
    }

    ngOnDestroy(): void {
        this.userProfileSubscription.unsubscribe();
    }

    getSubscriptionPermission(orgId?: number) {
        const organizationId: number = orgId || this.organizationId;

        return this._http.get(`${this.apiV2}/organization/${organizationId}/user/featurs-codes`, {
            headers: this._globalService.getHeader()
        });
    }

    getSubscriptionData(orgId?: number) {
        const organizationId: number = orgId || this.organizationId;

        return this._http.get(`${this.apiV2}/organization/${organizationId}/subscriptions/configs`, {
            headers: this._globalService.getHeader()
        });
    }

    getPreData() {
        return this._http.get(`${this.apiV2}/organization/setups`, {
            headers: this._globalService.getHeader()
        });
    }

    getTotalPayment(body) {
        return this._http.put(`${this.apiV2}/organization/${this.organizationId}/setups/total`, body, {
            headers: this._globalService.getHeader()
        });
    }

    getAbaFormInfo(body) {
        return this._http.post(`${this.apiV1}/payments/transactions`, body, {
            headers: this._globalService.getHeader()
        });
    }

    updateOrganization(body) {
        return this._http.put(`${this.apiV2}/organization/${this.organizationId}/setups`, body, {
            headers: this._globalService.getHeader()
        });
    }

    getPaymentMethods() {
        return this._http.get(`${this.apiV1}/payment-methods`, {
            headers: this._globalService.getHeader()
        });
    }
}