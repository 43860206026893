import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/shared/services/global.service';
import { PaginateParams } from '../model/global';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    endPoint = this._globalService.apiUrl;

    // version 2
    endPointV2 = this._globalService.apiUrlV2;

    constructor(private _globalService: GlobalService, private _http: HttpClient) {}

    // get company list
    // getCompany(params: PaginateParams) {
    //     return this._http.get(`${this.endPoint}/organization`, {
    //         headers: this._globalService.getHeader(),
    //         params: this._globalService.reGenerateParams(params)
    //     });
    // }

    // add company
    // addCompany(body) {
    //     return this._http.post(`${this.endPoint}/organization`, body, {
    //         headers: this._globalService.getHeader()
    //     });
    // }

    // get company detail
    // getCompanyDetail(id: number) {
    //     return this._http.get(`${this.endPoint}/organization/${id}`, {
    //         headers: this._globalService.getHeader()
    //     });
    // }

    // update company
    // updateOrganization(id: number, body) {
    //     return this._http.put(`${this.endPoint}/organization/${id}`, body, {
    //         headers: this._globalService.getHeader()
    //     });
    // }

    // delete organization
    deleteOrganization(id: number) {
        return this._http.delete(`${this.endPoint}/organization/${id}`, {
            headers: this._globalService.getHeader()
        });
    }

    /*=============
     * for hr-admin
     =============*/
    getOrganizationProfile() {
        return this._http.get(`${this.endPoint}/organization/profile`, {
            headers: this._globalService.getHeader()
        });
    }

    // add organization
    // addOrganization(body) {
    //     return this._http.post(`${this.endPoint}/organization/profile`, body, {
    //         headers: this._globalService.getHeader()
    //     });
    // }

    // update organization
    updateProfile(body) {
        return this._http.put(`${this.endPoint}/organization/profile`, body, {
            headers: this._globalService.getHeader()
        });
    }

    /**
     * Version 2
     */
    // get organization list
    getOrganizationList(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization`, {
            headers: this._globalService.getHeader(),
            params: this._globalService.reGenerateParams(params)
        });
    }

    exportOrganizations(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/exports`, {
            headers: this._globalService.getHeader(),
            params: this._globalService.reGenerateParams(params) as any,
            responseType: 'blob'
        });
    }

    // add organization
    addOrganization(body) {
        return this._http.post(`${this.endPointV2}/organization`, body, {
            headers: this._globalService.getHeader()
        });
    }

    // get organization detail
    getOrganizationDetail(id: number) {
        return this._http.get(`${this.endPointV2}/organization/${id}`, {
            headers: this._globalService.getHeader()
        });
    }

    // update organization
    updateOrganization(id: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${id}`, body, {
            headers: this._globalService.getHeader()
        });
    }

    // toggle organization
    toggleOrganization(id: number) {
        return this._http.put(`${this.endPointV2}/organization/${id}/toggle`, '', {
            headers: this._globalService.getHeader()
        });
    }

    getOrganizationMenus(orgId) {
        return this._http.get(`${this.endPointV2}/organization/${orgId}/menu`, {
            headers: this._globalService.getHeader()
        });
    }

    seenMenus(orgId, body) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/menu/${body.code}/seen`, body, {
            headers: this._globalService.getHeader()
        });
    }

    createMenus(orgId, body) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/menu`, body, {
            headers: this._globalService.getHeader()
        });
    }

    updateMenus(orgId, body) {
        return this._http.put(`${this.endPointV2}/organization/${orgId}/menu`, body, {
            headers: this._globalService.getHeader()
        });
    }

    deleteMenu(orgId, body) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/menu/delete`, body, {
            headers: this._globalService.getHeader()
        });
    }

    getPurchaseBilling(orgId) {
        return this._http.get(`${this.endPointV2}/organization/${orgId}/purchase/billing`, {
            headers: this._globalService.getHeader()
        });
    }

    getPurchaseToken(orgId) {
        return this._http.get(`${this.endPointV2}/organization/${orgId}/purchase/token`, {
            headers: this._globalService.getHeader()
        });
    }

    purchase(orgId, body) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/purchase`, body, {
            headers: this._globalService.getHeader()
        });
    }
}
