import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    authUrl = environment.apiUrl;
    url = `${environment.apiUrl}/v1`;
    urlV2 = `${environment.apiUrl}/v2`;
    urlV3 = `${environment.apiUrl}/v3`;
    apiUrl = `${environment.apiUrl}/v1/admin`;
    apiUrlV2 = `${environment.apiUrl}/v2/admin`;
    apiUrlV3 = `${environment.apiUrl}/v3/admin`;
    authorization = environment.authorization;
    clientId = environment.clientId;

    getHeader() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'x-timezone': moment.tz.guess(),
            Authorization: localStorage.getItem('token') || ''
        });
    }

    reGenerateParams(params) {
        Object.keys(params).map(x =>
            params[x] === null || params[x] === '' || params[x] === undefined
                ? delete params[x]
                : ''
        );
        return params;
    }
    constructor() {}
}
