import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appTrim]'
})
export class TrimDirective {
    constructor(private er: ElementRef) {}

    ngOnInit() {
        console.log(this.er);
        console.log(this.er.nativeElement.onchange);
    }
}
