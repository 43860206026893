import { Injectable } from '@angular/core';
import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Auth0Service {
    accessToken!: string;
    private auth0: WebAuth;
    private expiresAt = new Date().getTime();

    constructor() {
        this.auth0 = new WebAuth({
            domain: environment.auth0Domain,
            clientID: environment.auth0ClientId,
            audience: environment.auth0Audience,
            responseType: 'token id_token',
            redirectUri: `${window.location.origin}/callback`
        });
    }

    login(username: string, password: string) {
        return new Promise((resolve, reject) => {
            this.auth0.login(
                {
                    email: username.trim(),
                    password: password.trim(),
                    scope: 'update:users'
                },
                (err, authResult) => {
                    console.log('erro', err);
                    if (err) {
                        reject(err);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    logout() {
        this.removeLocalStorage();
        this.auth0.logout({ returnTo: window.location.origin });
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err || !authResult || !authResult.idToken)
                    return reject(err);

                this.setSession(authResult);
                resolve();
            });
        });
    }

    setSession(authResult: Auth0DecodedHash) {
        const t = new Date();
        const exp = t.setSeconds(t.getSeconds() + authResult.expiresIn! / 2);
        this.expiresAt = exp;
        localStorage.setItem('token', `Bearer ${authResult.accessToken!}`);
        localStorage.setItem(
            'authResult',
            JSON.stringify({ ...authResult, exp })
        );
    }

    removeLocalStorage() {
        localStorage.removeItem('authResult');
        localStorage.removeItem('token');
    }

    silentAuth() {
        return new Promise((resolve, reject) => {
            this.auth0.checkSession({}, (err, authResult) => {
                if (err) {
                    this.removeLocalStorage();
                    return reject(err);
                }
                this.setSession(authResult);
                resolve();
            });
        });
    }

    isAuthenticated() {
        // Check whether the current time is past the token's expiry time
        return new Date().getTime() < this.expiresAt;
    }
}
