import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
    forgotPwdPage: boolean = false;
    href: string = '';
    constructor(private route: Router) {}

    ngOnInit() {
        this.togglePage();
    }
    togglePage() {
        this.href = this.route.url;
        this.href = this.href.substr(1);

        if (this.href == 'forgot-password') {
            this.forgotPwdPage = true;
        } else {
            this.forgotPwdPage = false;
        }
    }
}
