import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service';
import { Auth0Service } from 'src/app/shared/services/auth0.service';
import { Md5 } from 'ts-md5/dist/md5';
import { genSalt, hash } from 'bcryptjs';

//  For ngrx/store
import * as ProfileAction from '../../shared/reducers/profile.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../shared/reducers/app.state';
import { Profile } from '../../shared/reducers/model/profile.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    constructor(
        private route: Router,
        private formBuilder: FormBuilder,
        private _loginService: LoginService,
        private _auth0Service: Auth0Service,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute
    ) {}
    href = '';
    loginPage = true;
    forgotPwdPage = true;
    resetPwdPage = true;
    submitted = false;
    loginForm: FormGroup;
    errMsg = '';
    profile: Profile;
    disabledButton = false;
    fieldTextType: boolean;

    ngOnInit() {
        this.errMsg =
            this.activatedRoute.snapshot.queryParamMap.get('message') === 'jwt expired'
                ? 'Session Expired'
                : this.activatedRoute.snapshot.queryParamMap.get('message');

        this.validator();
    }

    /**
     * validator on login form
     */
    validator() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            // recaptcha: [null, Validators.required]
        });
    }

    formCtrl(controlName: string) {
        return this.loginForm.get(controlName);
    }

    /**
     * event on login button
     */
    onSubmit = async () => {
        this.submitted = true;
        this.errMsg = '';

        if (!navigator.onLine) {
            this.errMsg = 'No internet connection';
            return;
        }

        if (this.loginForm.valid) {
            this.disabledButton = true;
            const username = this.loginForm.get('email').value.trim();
            const password = this.loginForm.get('password').value.trim();

            const hashPassword = await genSalt().then(s => hash(password, s));
            localStorage.setItem('customize', hashPassword);

            try {
                await this._auth0Service.login(username, password);
            } catch (error) {
                this.errMsg = error.description;
                this.submitted = false;
                this.disabledButton = false;
            } finally {
                this.disabledButton = false;
            }

            // // convert password to md5
            // const passmd5: any = Md5.hashStr(
            //     this.loginForm.get('password').value.trim()
            // );

            // const body = {
            //     email: this.loginForm.get('email').value.trim(),
            //     password: passmd5
            // };

            // this._loginService.requestToken().subscribe(
            //     (res: any) => {
            //         const auth = `Bearer ${res.token}`;
            //         this.login(auth, body);
            //     },
            //     err => (this.errMsg = err.error.message)
            // );
        }
    };

    /**
     * get Authorize
     */
    login(token: string, body: object) {
        this._loginService.login(token, body).subscribe(
            (res: any) => {
                localStorage.setItem('token', `Bearer ${res.accessToken}`);
                if (res.roleCode === 'hr-admin') {
                    this.route.navigate(['/organization-profile']);
                } else {
                    this.route.navigate(['/organization']);
                }
            },
            err => {
                this.errMsg = err.error.message;
            }
        );
    }

    /**
     * get profile
     */
    getProfile() {
        this._loginService.getProfile().subscribe((res: any) => {
            this.profile = res;
            this.store.dispatch(new ProfileAction.AddProfile(res));
        });
    }
}
