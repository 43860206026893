import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
    prohibited = {
        agree: [
            'is unconstitutional, illegal or unauthorized',
            `promotes discrimination on grounds of race, sex, faith, nationality, disability, sexual orientation or age; or`,
            'disrupt our Service in any way.'
        ],
        futhermore: [
            'solicit other users’ login information or attempt to access an account belonging to someone else;',
            `collect other users' content or information, or access the Service using automated means (such as harvesting bots, robots, spiders, or scrapers);`,
            `defame, bully, intimidate, harass, threaten or impersonate any user, and you will not display your or any other user’s confidential or private information using the Service;`,
            'offer any contest, giveaway, or sweepstakes using the Service without our prior written consent;',
            `do anything that could disable, overburden, or impair the proper working of the Service, such as a denial of service attack. You will not upload viruses or other malicious code;`,
            'put yourself or anyone else in danger while using the Service (this includes, without limitation, during the completion of tasks within the App); and',
            'facilitate or encourage any violations of these Terms of Use.'
        ]
    };

    privacyPolicies = [
        'We will only collect and use personal information to serve those purposes defined by us and for other appropriate purposes, unless we obtain the consent of the individual concerned or as permitted by law.',
        'Personal information should be appropriate to the purposes for which it is to be used and should be reliable, full, and up-to-date to the degree required for such purposes.',
        'We will protect personal information against loss or misuse through fair security protections, as well as unauthorized access, posting, copying, reuse, or alteration.',
        'We will retain personal information only for as long as it is necessary to serve those purposes.'
    ];

    securityPolices = [
      'Backend API Security',
      'Backend Cloud server security',
      'Database access security',
      'Data Security',
      'Authentication Security',
      'Web Application Security',
      'Database Backup',
      'Mobile Application Security'
    ]

    constructor(private Title: Title) {
        this.Title.setTitle('Terms of Use');
    }

    ngOnInit(): void {}
}
