import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
// import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertMessageService  {
    unknownError = 'Connection problem while processing your request'

    messageSuccess(): any {
        throw new Error('Method not implemented');
    }
    // constructor(
    //     private _alertService: Ng2IzitoastService,
        // public iziToast: Ng2IzitoastService
    // ) {}

    constructor(@Inject(Injector) private injector: Injector) { 
      }

    private get toastrService(): ToastrService {
        return this.injector.get(ToastrService);
      }

    alertSuccess(string: string) {
        // return this._alertService.show({
        //     message: string ? string.charAt(0).toUpperCase() + string.slice(1) : '',
        //     class: 'foo',
        //     progressBarColor: '#FFF',
        //     // backgroundColor: '#006096',
        //     backgroundColor: '#0139a2',
        //     titleColor: '#FFF',
        //     messageColor: '#FFF',
        //     close: false,
        //     iconColor: '#FFF'
        // });

        return this.toastrService.success(string ? string.charAt(0).toUpperCase() + string.slice(1) : '')
    }

    /*==============
        Alert Error
    ===============*/
    alertError(string: string = this.unknownError) {
        // return this._alertService.show({
        //     message: string ? string.charAt(0).toUpperCase() + string.slice(1) : '',
        //     class: 'foo',
        //     progressBarColor: '#D8000C',
        //     backgroundColor: '#FFD2D2',
        //     titleColor: 'D8000C',
        //     messageColor: '#D8000C',
        //     close: false
        // });

        return this.toastrService.error(string ? string.charAt(0).toUpperCase() + string.slice(1) : '');
    }
}
