import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { HttpClient } from '@angular/common/http';
import { PaginateParams } from '../model/global';

import {
    PointType,
    GetPermissionResponse,
    ResponsePosition,
    GetStructureParams,
    StructureCategory,
    Structure,
    TimeOffGroup,
    TimeOff,
    HolidayGroup,
    Holiday
} from '../model/setup';
import { CurrentUserService } from 'src/app/shared/services/current-user.service';

@Injectable({
    providedIn: 'root'
})
export class SetupService {
    header = this._globalService.getHeader();
    endPoint = this._globalService.apiUrl;
    endPointV2 = this._globalService.apiUrlV2;
    url_v2 = this._globalService.urlV2;
    organizationId = this._profileService.profile ? this._profileService.profile.organizationId : null;
    userId = this._profileService.profile ? this._profileService.profile.id : null;

    constructor(
        private _globalService: GlobalService,
        private _http: HttpClient,
        private _profileService: CurrentUserService
    ) {}

    // star service

    getPointTemplate(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/point/template`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postPointTemplate(body) {
        return this._http.post(`${this.endPointV2}/organization/${this.organizationId}/point/template`, body, {
            headers: this.header
        });
    }

    postPointTemplateOrder(body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/point/template/reorder`, body, {
            headers: this.header
        });
    }

    updatePointTemplate(body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/point/template`, body, {
            headers: this.header
        });
    }

    updateSinglePointTemplate(id, body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/point/template/${id}`, body, {
            headers: this.header
        });
    }

    updatePointTemplateOrder(body) {
        return this._http.put(`${this.endPoint}/point-template/orders`, body, {
            headers: this.header
        });
    }

    deletePointTemplate(id) {
        return this._http.delete(`${this.endPointV2}/organization/${this.organizationId}/point/template/${id}`, {
            headers: this.header
        });
    }

    /**--------------------
     * give star to person
     --------------------*/

    postGiveAwayStar(body) {
        return this._http.post(`${this.url_v2}/transaction/giveaway-admin`, body, {
            headers: this.header
        });
    }

    postBonusStar(body) {
        return this._http.post(`${this.url_v2}/transaction/give-redeemable-admin`, body, {
            headers: this.header
        });
    }

    /**================
     * point supply
     ==================*/

    getPointSupply(params: PointType) {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/point/scheduler`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postPointSupply(body) {
        return this._http.post(`${this.endPointV2}/organization/${this.organizationId}/point/scheduler`, body, {
            headers: this.header
        });
    }

    updatePointSupply(id, body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/point/scheduler/${id}`, body, {
            headers: this.header
        });
    }

    deletePointSupply(id) {
        return this._http.delete(`${this.endPointV2}/organization/${this.organizationId}/point/scheduler/${id}`, {
            headers: this.header
        });
    }

    /**==========
     * Holiday
     ==========*/
    getHolidayList() {
        return this._http.get(`${this.endPoint}/event/public-holiday`, {
            headers: this.header
        });
    }

    postHolidayList() {
        return this._http.post(`${this.endPoint}/event/public-holiday`, '', {
            headers: this.header
        });
    }
    updateHolidayList(body) {
        return this._http.put(`${this.endPoint}/event/public-holiday`, body, {
            headers: this.header
        });
    }

    getHolidayGroup(orgId, year) {
        return this._http.get(`${this.endPointV2}/organization/${orgId}/calendar/holiday-group`, {
            headers: this.header,
            params: {
                timeOffStatus: 'active',
                status: 'active',
                year
            } as any
        });
    }

    addHolidayGroup(orgId, body: HolidayGroup) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/calendar/holiday-group`, body, {
            headers: this.header
        });
    }

    updateHolidayGroup(orgId, groupId: number, body: HolidayGroup) {
        return this._http.put(`${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}`, body, {
            headers: this.header
        });
    }

    deleteHolidayGroup(orgId, groupId: number) {
        return this._http.delete(`${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}`, {
            headers: this.header
        });
    }

    addHoliday(orgId, groupId: number, body: HolidayGroup) {
        return this._http.post(
            `${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}/holiday`,
            body,
            {
                headers: this.header
            }
        );
    }

    updateHoliday(orgId, groupId: number, timeOffId: number, body: HolidayGroup) {
        return this._http.put(
            `${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}/holiday/${timeOffId}`,
            body,
            {
                headers: this.header
            }
        );
    }

    toggleHoliday(orgId, groupId: number, timeOffId: number) {
        return this._http.delete(
            `${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}/holiday/${timeOffId}`,
            {
                headers: this.header
            }
        );
    }

    deleteHoliday(orgId, groupId: number, holidayId: number) {
        return this._http.delete(
            `${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}/holiday/${holidayId}/delete`,
            {
                headers: this.header
            }
        );
    }

    /**================
    * time off service
    ==================*/
    getTimeOffType() {
        return this._http.get(`${this.endPoint}/time-off-type`, {
            headers: this.header
        });
    }

    addTypeOffType(body) {
        return this._http.post(`${this.endPoint}/time-off-type`, body, {
            headers: this.header
        });
    }

    updateTypeOffType(id: number, body) {
        return this._http.put(`${this.endPoint}/time-off-type/${id}`, body, {
            headers: this.header
        });
    }

    deleteTypeOffType(id: number) {
        return this._http.delete(`${this.endPoint}/time-off-type/${id}`, {
            headers: this.header
        });
    }

    getTimeOffGroup(orgId) {
        return this._http.get(`${this.endPointV2}/organization/${orgId}/calendar/time-off-group`, {
            headers: this.header,
            params: {
                timeOffStatus: 'active',
                status: 'active'
            } as any
        });
    }

    addTimeOffGroup(orgId, body: TimeOffGroup) {
        return this._http.post(`${this.endPointV2}/organization/${orgId}/calendar/time-off-group`, body, {
            headers: this.header
        });
    }

    updateTimeOffGroup(orgId, groupId: number, body: TimeOffGroup) {
        return this._http.put(`${this.endPointV2}/organization/${orgId}/calendar/time-off-group/${groupId}`, body, {
            headers: this.header
        });
    }

    deleteTimeOffGroup(orgId, groupId: number) {
        return this._http.delete(`${this.endPointV2}/organization/${orgId}/calendar/time-off-group/${groupId}`, {
            headers: this.header
        });
    }

    addTimeOff(orgId, groupId: number, body: TimeOff) {
        return this._http.post(
            `${this.endPointV2}/organization/${orgId}/calendar/time-off-group/${groupId}/time-off`,
            body,
            {
                headers: this.header
            }
        );
    }

    updateTimeOff(orgId, groupId: number, timeOffId: number, body: TimeOff) {
        return this._http.put(
            `${this.endPointV2}/organization/${orgId}/calendar/time-off-group/${groupId}/time-off/${timeOffId}`,
            body,
            {
                headers: this.header
            }
        );
    }

    deleteTimeOff(orgId, groupId: number, timeOffId: number) {
        return this._http.delete(
            `${this.endPointV2}/organization/${orgId}/calendar/time-off-group/${groupId}/time-off/${timeOffId}`,
            {
                headers: this.header
            }
        );
    }

    /**============
     * position
     =============*/

    getPositionList(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/position/list`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postPosition(body) {
        return this._http.post(`${this.endPointV2}/organization/${this.organizationId}/position`, body, {
            headers: this.header
        });
    }

    updatePosition(id: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/position/${id}`, body, {
            headers: this.header
        });
    }

    updatePositionLevel(body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/position/batch-update`, body, {
            headers: this.header
        });
    }

    deletePosition(id: number) {
        return this._http.delete(`${this.endPointV2}/organization/${this.organizationId}/position/${id}`, {
            headers: this.header
        });
    }

    getPermission(params: GetPermissionResponse) {
        return this._http.get(`${this.url_v2}/data-service/permission`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    getLevel() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/position/group`, {
            headers: this.header
        });
    }

    /**============
     * structure
     =============*/
    getStructureCategory() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/structure-category`, {
            headers: this.header,
            params: {
                offset: 0,
                limit: 500
            } as any
        });
    }

    addStructureCategory(id: number, body: StructureCategory) {
        return this._http.post(`${this.endPointV2}/organization/${id}/structure-category`, body, {
            headers: this.header
        });
    }

    updateStructureCategory(id: number, organizationId: number, body: StructureCategory) {
        return this._http.put(`${this.endPointV2}/organization/${organizationId}/structure-category/${id}`, body, {
            headers: this.header
        });
    }

    deleteStructureCategory(id: number, organizationId: number) {
        return this._http.delete(`${this.endPointV2}/organization/${organizationId}/structure-category/${id}`, {
            headers: this.header
        });
    }

    getStructure() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/structure`, {
            headers: this.header,
            params: {
                offset: 0,
                limit: 500
            } as any
        });
    }

    addStructure(id: number, body: Structure) {
        return this._http.post(`${this.endPointV2}/organization/${id}/structure`, body, {
            headers: this.header
        });
    }

    updateStructure(id: number, organizationId: number, body: Structure) {
        return this._http.put(`${this.endPointV2}/organization/${organizationId}/structure/${id}`, body, {
            headers: this.header
        });
    }

    deleteStructure(id: number, organizationId: number) {
        return this._http.delete(`${this.endPointV2}/organization/${organizationId}/structure/${id}`, {
            headers: this.header
        });
    }

    // getUserUnlimitedReward
    getUser(organizationId: number) {
        return this._http.get(`${this.endPointV2}/organization/${organizationId}/user`, {
            headers: this.header
        });
    }

    getUserUnlimitedReward(organizationId: number, params) {
        return this._http.get(`${this.endPointV2}/organization/${organizationId}/user`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }
    addUserUnlimitedReward(organizationId: number, body) {
        return this._http.post(`${this.endPointV2}/organization/${organizationId}/user/unlimited-point`, body, {
            headers: this.header
        });
    }
    removeUserUnlimitedReward(organizationId: number, id: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${organizationId}/user/unlimited-point/${id}`, body, {
            headers: this.header
        });
    }
    // authorize HR
    getUserFilter(organizationId: number, params) {
        return this._http.get(`${this.endPointV2}/organization/${organizationId}/user`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }
    assignUserPrivileges(organizationId: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${organizationId}/user/user-privileges`, body, {
            headers: this.header
        });
    }
    removeUserPrivileges(organizationId: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${organizationId}/user/user-privileges/remove`, body, {
            headers: this.header
        });
    }

    /**==========
     * Working Day
     ==========*/

    getWorkingDayGroup() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule`, {
            headers: this.header,
            params: {
                timeOffStatus: 'active',
                status: 'active'
            } as any
        });
    }

    addWorkingDayGroup(body: HolidayGroup) {
        return this._http.post(
            `${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule`,
            body,
            {
                headers: this.header
            }
        );
    }

    updateWorkingDayGroup(groupId: number, body) {
        return this._http.put(
            `${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule/${groupId}`,
            body,
            {
                headers: this.header
            }
        );
    }

    deleteWorkingDayGroup(groupId: number) {
        return this._http.delete(
            `${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule/${groupId}`,
            {
                headers: this.header
            }
        );
    }

    addWorkingDay(groupId: number, body) {
        return this._http.post(
            `${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule/${groupId}/working-day`,
            body,
            {
                headers: this.header
            }
        );
    }

    updateWorkingDay(groupId: number, body) {
        return this._http.put(
            `${this.endPointV2}/organization/${this.organizationId}/calendar/working-schedule/${groupId}/working-day`,
            body,
            {
                headers: this.header
            }
        );
    }

    // deleteWorkingDay(orgId, groupId: number, timeOffId: number) {
    //     return this._http.delete(
    //         `${this.endPointV2}/organization/${orgId}/calendar/holiday-group/${groupId}/holiday/${timeOffId}`,
    //         {
    //             headers: this.header
    //         }
    //     );
    // }

    /*====================
     * Role
     ===================*/
    getRole(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/role`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postRole(body) {
        return this._http.post(`${this.endPointV2}/organization/${this.organizationId}/role`, body, {
            headers: this.header
        });
    }

    updateRole(id: number, body) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/role/${id}`, body, {
            headers: this.header
        });
    }

    deleteRole(id: number) {
        return this._http.delete(`${this.endPointV2}/organization/${this.organizationId}/role/${id}`, {
            headers: this.header
        });
    }

    // getPermission(params: GetPermissionResponse) {
    //     return this._http.get(`${this.url_v2}/data-service/permission`, {
    //         headers: this.header,
    //         params: this._globalService.reGenerateParams(params) as any
    //     });
    // }

    /*====================
     * Localize
     ===================*/
    getLocalizeList(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/translation`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    updateLocalize(body) {
        return this._http.put(`${this.endPointV2}/organization/translation/translation`, body, {
            headers: this.header
        });
    }

    postLocalize(body) {
        return this._http.post(`${this.endPointV2}/organization/translation`, body, {
            headers: this.header
        });
    }

    deleteLocalize(key: string) {
        return this._http.delete(`${this.endPointV2}/organization/translation/key/${key}`, {
            headers: this.header
        });
    }

    getLanguageList(params: PaginateParams) {
        return this._http.get(`${this.endPointV2}/organization/translation/language`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postLanguage(body) {
        return this._http.post(`${this.endPointV2}/organization/translation/language`, body, {
            headers: this.header
        });
    }

    getLocalizeHrList(params) {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/localize/${params.fileName}`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params) as any
        });
    }

    postLocalizeHr(body, fileName) {
        return this._http.put(`${this.endPointV2}/organization/${this.organizationId}/localize/${fileName}`, body, {
            headers: this.header
        });
    }

    deleteLanguage(key: string) {
        return this._http.delete(`${this.endPointV2}/organization/translation/language/${key}`, {
            headers: this.header
        });
    }

    agreeTerms() {
        return this._http.patch(
            `${this.endPointV2}/organization/${this.organizationId}/user/${this.userId}`,
            { isTermsAgreed: true },
            {
                headers: this.header
            }
        );
    }

    getReminder() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/notification-reminder`, {
            headers: this.header
        });
    }

    updateReminder(body) {
        return this._http.post(`${this.endPointV2}/organization/${this.organizationId}/notification-reminder`, body, {
            headers: this.header
        });
    }

    getRewardStructure() {
        return this._http.get(`${this.endPointV2}/organization/${this.organizationId}/structure-category/rewardList`, {
            headers: this.header,
            params: {
                offset: 0,
                limit: 500
            } as any
        });
    }
}
