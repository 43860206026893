import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from '../services/login.service';
import { AppState } from '../reducers/app.state';
import { Store, select } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import * as ProfileAction from '../reducers/profile.actions';

@Injectable({
    providedIn: 'root'
})
export class OnBoardingAuthGuard implements CanActivate {
    constructor(private router: Router, private _loginService: LoginService, private store: Store<AppState>) {}
    /**
     * Check and Set Data to Ngrx Store
     */
    getFromStoreOrAPI(): Observable<any> {
        return this.store.pipe(
            // get data from ngrx store
            select('profile'),
            tap((data: any) => {
                // check if ngrx store doesn't has data
                if (!data.length) {
                    // request data from api
                    if (localStorage.getItem('token')) {
                        this._loginService.getProfile().subscribe(
                            (res: any) => {
                                if (res.roleId === 0) {
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('organizations');
                                    const message = `Sorry! You don't have right to login. Please contact to your admin.`;
                                    this.router.navigate(['/login'], {
                                        queryParams: { message }
                                    });
                                }

                                if (res?.organization?.isSetup) {
                                    this.router.navigate(['/']);
                                } else {
                                    this.store.dispatch(new ProfileAction.RemoveProfile(0));

                                    // set data to ngrx store
                                    this.store.dispatch(new ProfileAction.AddProfile(res));
                                }
                            },
                            err => {
                                localStorage.removeItem('token');
                                localStorage.removeItem('organizations');
                                this.router.navigate(['/login'], {
                                    queryParams: {
                                        message: err && err.error ? err.error.message : ''
                                    }
                                });
                            }
                        );
                    }
                }
            }),
            // check data in ngrx store again if has data
            filter((data: any) => data.length),
            take(1)
        );
    }

    canActivate() {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}
