import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { SearchService } from './modules/hr/shared/service/search.service';
import { filter } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app/shared/reducers/app.state';
import * as ProdileActions from '../app/shared/reducers/profile.actions';
import { environment } from 'src/environments/environment';
import { Auth0Service } from './shared/services/auth0.service';
import { AlertMessageService } from './shared/services/alert-message.service';
const { idleTimeOut } = environment;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
    title = 'VIP';
    url: string;

    constructor(
        private router: Router,
        private searchService: SearchService,
        private idle: Idle,
        private _store: Store<AppState>,
        private _auth0Service: Auth0Service,
        private _alert: AlertMessageService
    ) {
        // sets an idle timeout into seconds
        // Reference : https://hackedbychinese.github.io/ng2-idle/
        this.idle.setIdle(+idleTimeOut);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleStart.subscribe(() => this.logOut());
        this.router.events
            .pipe(filter(e => e instanceof RoutesRecognized))
            .subscribe((e: any) => {
                // handle idle when route change
                if (localStorage.getItem('token')) {
                    this.idle.watch();
                }

                let result;
                if (e.url.includes('?')) {
                    const str = e.url;
                    result = str.substring(0, str.indexOf('?'));
                } else if (e.url.includes('position')) {
                    result = '/position';
                } else {
                    result = e.url;
                }
                // console.log(result);
                this.searchService.setUrlSubject(result);
            });

        this.setGTagManager();
    }

    async ngOnInit() {
        await this._auth0Service.silentAuth();

        /*** Track internet connection ***/
        window.addEventListener('online', () => this._alert.alertSuccess("Back online"));
        window.addEventListener('offline', () => this._alert.alertError("No internet connection"));
    }

    logOut() {
        this._store.dispatch(new ProdileActions.RemoveProfile(0));
        this._auth0Service.logout();
        // localStorage.removeItem('token');
        // if (!localStorage.getItem('token')) {
        //     location.reload();
        // }
    }

    /*** Set up Google Analytic in header tag ***/
    setGTagManager() {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtagId}`;

        const head1 = document.getElementsByTagName('head')[0];
        head1.appendChild(script1);

        const script2 = document.createElement('script');
        script2.innerHTML =
            `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());

                gtag('config', '${environment.gtagId}');
            `;

        const head2 = document.getElementsByTagName('head')[0];
        head2.appendChild(script2);
    }
}
