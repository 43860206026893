import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from '../services/login.service';
import { AppState } from '../reducers/app.state';
import { Store, select } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import * as ProfileAction from '../../shared/reducers/profile.actions';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private _loginService: LoginService,
        private store: Store<AppState>
    ) {}
    /**
     * Check and Set Data to Ngrx Store
     */
    getFromStoreOrAPI(): Observable<any> {
        return this.store.pipe(
            // get data from ngrx store
            select('profile'),
            tap((data: any) => {
                // check if ngrx store doesn't has data
                if (!data.length) {
                    // request data from api
                    this._loginService.getCurrentProfile();
                }
            }),
            // check data in ngrx store again if has data
            filter((data: any) => data.length),
            take(1)
        );
    }
    // canActivate(
    //     next: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot
    // ): Observable<boolean> | Promise<boolean> | boolean {
    //     return true;
    // }
    canActivate() {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}
