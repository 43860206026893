import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    searchStrSubject = new BehaviorSubject('');
    urlSubject = new BehaviorSubject('');
    isSearch = new BehaviorSubject({ search: false });

    constructor() {}

    // set value to store search string in behaviorSubject
    setSearch(value) {
        this.searchStrSubject.next(value);
    }

    // set value to store url string in behaviorSubject
    setUrlSubject(value) {
        this.urlSubject.next(value);
    }

    // set if that list have search or not to toggle textbox of search
    setIsSearch(value) {
        this.isSearch.next(value);
    }
}
