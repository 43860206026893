import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    constructor(
        private formBuilder: FormBuilder,
        private _loginService: LoginService,
        private route: Router
    ) {}

    errMsg = '';
    forgotForm: FormGroup;
    emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
    submitted = false;
    auth: string;

    ngOnInit() {
        this.validator();
    }

    /**
     * validator on form
     */
    validator() {
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    /**
     * event on forget password button
     */
    onSubmit() {
        this.submitted = true;
        this.errMsg = '';

        if (!navigator.onLine) {
            this.errMsg = 'No internet connection';
            return;
        };
        
        let body;
        if (this.forgotForm.valid) {
            this._loginService.requestToken().subscribe(
                (res: any) => {
                    const auth = `Bearer ${res.token}`;
                    this.forgotPwd(body, auth);
                },
                err => {
                    this.errMsg = err.error.message;
                }
            );
            body = {
                email: this.forgotForm.get('email').value.trim()
            };
        }
    }

    forgotPwd(body: any, auth: string) {
        this._loginService.forgotPwd(body, auth).subscribe(
            (res: any) => {
                this.route.navigate(['/login']);
            },
            err => {
                this.errMsg = err.error.message;
            }
        );
    }
}
