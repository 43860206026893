import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrganizationService } from 'src/app/modules/hr/shared/service/organization.service';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/app.state';
import * as ProfileAction from '../reducers/profile.actions';
import { Router } from '@angular/router';

import { PERMISSIONS } from '../../shared/permission-constants';
import { OnboardingService } from 'src/app/modules/hr/shared/service/onboarding.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    authUrl = this.globalService.authUrl;
    url = this.globalService.url;
    apiUrl = this.globalService.apiUrl;
    apiUrlV2 = this.globalService.apiUrlV2;
    // header = this.globalService.getHeader();
    udid: string = Math.floor((1 + Math.random()) * 0x10000000000000000000).toString(20);
    latitude = null;
    longitude = null;
    authorization = this.globalService.authorization;
    clientId = this.globalService.clientId;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(
        private globalService: GlobalService,
        private http: HttpClient,
        private _organizationService: OrganizationService,
        private store: Store<AppState>,
        private router: Router,
        private onboardingService: OnboardingService,
    ) { }

    getLoginHeader(token: string) {
        window.navigator.geolocation.getCurrentPosition(res => {
            this.latitude = res.coords.latitude;
            this.longitude = res.coords.longitude;
        });

        return new HttpHeaders({
            token: token,
            'Content-Type': 'application/json',
            'x-udid': this.udid,
            'x-os-version': '',
            'x-platform': 'web',
            'x-app-version': '',
            'x-forwarded-for': '',
            // 'x-latitude': this.latitude || '',
            // 'x-longitude': this.longitude || '',
            'x-user-agent': window.navigator.userAgent || '',
            'x-timezone': this.timezone
        });
    }

    getAuthorizeHeader() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            authorization: this.authorization,
            'x-udid': Math.floor((1 + Math.random()) * 0x1000000000).toString(16)
        });
    }

    requestToken() {
        return this.http.post(
            `${this.authUrl}/auth/authorize`,
            {
                clientId: this.clientId
            },
            {
                headers: this.getAuthorizeHeader()
            }
        );
    }

    login(token: string, body: object) {
        return this.http.post(`${this.authUrl}/auth/login`, body, {
            headers: this.getLoginHeader(token)
        });
    }

    forgotPwd(email: any, token: string) {
        return this.http.post(`${this.authUrl}/auth/forget-password`, email, {
            headers: this.getLoginHeader(token)
        });
    }

    resetPwd(body: any, token: string) {
        return this.http.post(`${this.authUrl}/auth/reset-password`, body, {
            headers: this.getLoginHeader(token)
        });
    }

    changePassword(body: any, token: string) {
        return this.http.post(`${this.authUrl}/auth/change-password`, body, {
            headers: this.getLoginHeader(token)
        });
    }

    getProfile() {
        return this.http.get(`${this.authUrl}/auth/profile`, {
            headers: this.globalService.getHeader()
        });
    }

    changePwd(organizationId, body) {
        // console.log('header', this.header);

        return this.http.put(`${this.apiUrlV2}/organization/${organizationId}/user/change-password`, body, {
            // headers: this.header
            headers: this.globalService.getHeader()
        });
    }

    checkTokenExpired(token, auth) {
        return this.http.get(`${this.authUrl}/auth/check-token-change-password?token=${token}`, {
            headers: this.getLoginHeader(auth)
        });
    }

    getCurrentProfile() {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('token')) {
                this.getProfile().subscribe(
                    (res: any) => {
                        if (res.roleId === 0) {
                            localStorage.removeItem('token');
                            localStorage.removeItem('organizations');
                            const message = `Sorry! You don't have right to login. Please contact to your admin.`;
                            this.router.navigate(['/login'], {
                                queryParams: { message }
                            });
                            return reject(message);
                        }

                        if (!res?.organization?.isSetup) {
                            this.router.navigate(['/onboarding']);
                        }

                        res.organization.hasProfile = res.organization.latitude ? true : false;
                        res.isSystemAdmin = res.permissions.includes(PERMISSIONS.ORGANIZATION.CREATE);
                        res.isLead = res.lead && res.lead.id ? true : false; //res.permissions.includes(PERMISSIONS.LEAD.READ);
                        if (res.isSuperAdmin) {
                            res.permissions.push('ORG_SUPER_ADMIN');
                        }
                        if (!res.isSystemAdmin) {
                            res.isEnablePrizeButton = [1, 23, 29].includes(res.organization.id);

                            /*** Get Menu ****/
                            this._organizationService.getOrganizationMenus(res.organization.id).subscribe(
                                (resMenus: any) => {
                                    res.menus = resMenus;

                                    /*** Get Current Subscription plan ***/
                                    this.onboardingService.getSubscriptionData(res.organizationId).subscribe(
                                        (plan) => {
                                            res.subscriptionData = plan;

                                            /*** Get Current Subscription's feature permissions ***/
                                            this.onboardingService.getSubscriptionPermission(res.organizationId).subscribe(
                                                (feature: any) => {
                                                    res.subscriptionBannedFeatureCodes = feature?.data?.excludeFeatureCodes || [];
                                                    res.featureCurrentTotals = feature?.data?.currentUsages?.map(cu => cu ? ({ ...cu, code: cu?.code?.toLowerCase() }) : cu);
                                                    
                                                    this.storeProfile(resolve, res);
                                                },
                                                () => this.storeProfile(resolve, res))
                                        },
                                        () => this.storeProfile(resolve, res))
                                },
                                () => this.storeProfile(resolve, res)
                            );
                        } else this.storeProfile(resolve, res)
                    },
                    err => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('organizations');
                        this.router.navigate(['/login'], {
                            queryParams: {
                                message: err && err.error ? err.error.message : ''
                            }
                        });
                        return reject(err);
                    }
                );
            } else {
                this.router.navigate(['/login']);
                return reject();
            }
        });
    }

    storeProfile(resolve, res) {
        this.store.dispatch(new ProfileAction.RemoveProfile(0));
        // set data to ngrx store
        this.store.dispatch(new ProfileAction.AddProfile(res));
        resolve(res as any);
    }
}
