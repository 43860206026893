import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    errMsg = '';
    submitted = false;
    passwordForm: FormGroup;
    auth: string;
    resetToken: string;
    fieldTextType: boolean;
    fieldTextTypeNewPassword: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private route: Router,
        private formBuilder: FormBuilder,
        private _loginService: LoginService,
        private _alert: AlertMessageService
    ) {}

    ngOnInit() {
        this.validator();
        this.resetToken = this.activatedRoute.snapshot.queryParamMap.get('token');

        this._loginService.requestToken().subscribe(
            (res: any) => {
                const auth = `Bearer ${res.token}`;

                this._loginService.checkTokenExpired(this.resetToken, auth).subscribe(
                    (resCheck: any) => {
                        if (resCheck?.isExpired) this.route.navigate(['/login']);
                    },
                    err => {
                        this._alert.alertError(err.error.message);
                    }
                );
            },
            err => (this.errMsg = err.message)
        );
    }

    /**
     * validator on form
     */
    validator() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPwd: ['', [Validators.required]]
        });
    }

    /**
     * event on submit button
     */
    onSubmit() {
        this.submitted = true;
        this.errMsg = '';

        if (!navigator.onLine) {
            this.errMsg = 'No internet connection';
            return;
        }

        if (this.passwordForm.get('password').value.trim() !== this.passwordForm.get('confirmPwd').value.trim()) {
            return;
        }
        if (this.passwordForm.valid) {
            const body = {
                password: this.passwordForm.get('password').value.trim(),
                token: this.resetToken
            };
            this._loginService.requestToken().subscribe(
                (res: any) => {
                    const auth = `Bearer ${res.token}`;
                    this.changePassword(body, auth);
                },
                err => (this.errMsg = err.message)
            );
        }
    }

    changePassword(body: any, auth: string) {
        this._loginService.changePassword(body, auth).subscribe(
            (res: any) => {
                this.route.navigate(['/login']);
            },
            err => {
                this.errMsg = err.error.message;
            }
        );
    }
}
