// Section 1
import { Action } from "@ngrx/store";
import { Profile } from "./model/profile.model";

// Section 2
export const ADD_PROFILE = "[PROFILE] Add";
export const REMOVE_PROFILE = "[PROFILE] Remove";

// Section 3
export class AddProfile implements Action {
    readonly type = ADD_PROFILE;

    constructor(public payload: Profile) {}
}

export class RemoveProfile implements Action {
    readonly type = REMOVE_PROFILE;

    constructor(public payload: number) {}
}

// Section 4
export type Actions = AddProfile | RemoveProfile;
