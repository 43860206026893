import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule, MatGridListModule } from '@angular/material';
import { TestDirective } from './directives/test.directive';
import { TestPipe } from './pipes/test.pipe';
import { TrimDirective } from './directives/trim.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { RunHTMLScriptDirective } from './directives/run-htmlscript.directive';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { AppDateFormatPipe } from './pipes/date-format.pipe';
import { AppTimeFormatPipe } from './pipes/time-format.pipe';
import { EventMemberTypePipe } from './pipes/event-member-type.pipe';

const pipes = [
    TestDirective,
    TestPipe,
    TrimDirective,
    EllipsisPipe,
    ShortNumberPipe,
    RunHTMLScriptDirective,
    SafeHTMLPipe,
    AppDateFormatPipe,
    AppTimeFormatPipe,
    EventMemberTypePipe
];

@NgModule({
    imports: [CommonModule, MatCardModule, MatGridListModule],
    declarations: [...pipes],
    exports: [MatCardModule, MatGridListModule, ...pipes],
    providers: [DatePipe]
})
export class SharedModule { }
