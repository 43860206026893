import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/guards/AuthGuard';
import { ResetPasswordComponent } from './layouts/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './layouts/forgot-password/forgot-password.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './layouts/login/login.component';
import { PrivacyBackupComponent } from './modules/privacy-backup/privacy-backup.component';
import { CallbackComponent } from './modules/auth/callback/callback.component';
import { TermsComponent } from './modules/terms/terms.component';
// import { TermsOfUseBackupComponent } from './modules/terms-of-use-backup/terms-of-use-backup.component';
import { AgreeTermsComponent } from './modules/hr/agree-terms/agree-terms.component';
import { ChangePasswordComponent } from './layouts/change-password/change-password.component';
import { OnBoardingAuthGuard } from './shared/guards/OnBoardingAuthGuard';
import { TermsOfUseComponent } from './modules/terms-of-use/terms-of-use.component';
import { TermsOfUseBackupComponent } from './modules/terms-of-use-backup/terms-of-use-backup.component';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { DisplayScreenComponent } from './modules/display-screen/display-screen.component';
// import { NotFoundComponent } from "./layouts/not-found/not-found.component";

export const router: Routes = [
    {
        path: '',
        loadChildren: () => import('./layouts/main/main.module').then(m => m.MainModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
        canActivate: [OnBoardingAuthGuard]
    },
    {
        path: 'display-screen',
        children: [
            {
                path: '',
                component: DisplayScreenComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'reset/password',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'forgot-password',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            }
        ]
    },
    {
        path: 'change-password',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: ChangePasswordComponent
            }
        ]
    },
    {
        path: 'not-found',
        canActivate: [AuthGuard],
        loadChildren: () => import('./layouts/not-found/not-found.module').then(m => m.NotFoundModule)
    },
    //!! for now close this condition following jira ticket
    // {
    //     path: 'agree-terms',
    //     component: LoginLayoutComponent,
    //     canActivate: [AuthGuard],
    //     children: [
    //         {
    //             path: '',
    //             component: AgreeTermsComponent
    //         }
    //     ]
    // },
    // !!! TODO: this one not delete, just incase it back ...
    {
        path: 'privacy-backup',
        component: PrivacyBackupComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'terms-auction',
        component: TermsComponent
    },
    {
        path: 'terms',
        component: TermsOfUseComponent
    },
    // !!! TODO: this one not delete, just incase it back ...
    {
        path: 'terms-backup',
        component: TermsOfUseBackupComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'testing',
        loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'not-found'
    }
];

export const appComponents = [
    LoginLayoutComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    PrivacyBackupComponent,
    TermsComponent,
    TermsOfUseBackupComponent,
    AgreeTermsComponent
];
export const routes: ModuleWithProviders = RouterModule.forRoot(router, {
    preloadingStrategy: PreloadAllModules
});
