import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-display-screen',
    template: ` <iframe #iframe width="100%" height="100%"></iframe> `,
    styles: [
        `
            iframe {
                height: 100dvh;
            }
        `
    ]
})
export class DisplayScreenComponent {
    @ViewChild('iframe') iframe: ElementRef;

    constructor() {}

    ngAfterViewInit(): void {
        document.body.style.overflow = 'hidden';

        if (this.iframe) {
            this.iframe.nativeElement.src = 'https://stars-screen-display.netlify.app/information';
        }
    }
}
