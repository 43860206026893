import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'appDateFormat'
})
export class AppDateFormatPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: any, hasTime = true) {
        const d = new Date(value);
        const dd = value && !d.toString().toLowerCase().includes('invalid') ? d : '';
        if (hasTime) return this.datePipe.transform(dd, 'MMM d, y hh:mm a');
        else return this.datePipe.transform(dd, 'MMM d, y');
    }
}
