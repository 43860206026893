import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-terms-of-use-backup',
    templateUrl: './terms-of-use-backup.component.html',
    styleUrls: ['./terms-of-use-backup.component.scss']
})
export class TermsOfUseBackupComponent implements OnInit {
    constructor(private title: Title) {}

    ngOnInit() {
        this.title.setTitle('Terms of Use');
    }
}
